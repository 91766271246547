<template>
    <div class="mb-2 mt-2">

        <infinity-list
                url="/deal_emails"
                :refresh-list.sync="refreshList"
                :queryParams="{deal_id: moduleItem.id}"
                height="2000px"
        >
            <template #item="{item, ind}">
                <b-media tag="li" no-body>

                    <b-media-body>
                        <div class="mail-details">
                            <div class="mail-items">
                                <h5 class="mb-25 d-flex align-items-center">
                                    <span>
                                        <strong class="text-primary">{{item.name}}</strong>

                                        {{$t('label_posted_on')}} - <span class="text-info">
                                        {{formatDate(item.created_at, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss')}}</span>&nbsp;

                                        <span class="text-success">{{item.title}} </span>
                                    </span>

                                    <b-button @click="resendMail(item.id)" variant="outline-primary" size="sm" pill class="ml-1"
                                              v-if="item.is_sended == 0 && item.canResend"
                                        :title="$t('question_resend')">
                                        <feather-icon icon="RepeatIcon"></feather-icon>
                                    </b-button>

                                </h5>

                            </div>

                        </div>

                        <div class="mail-message" v-html="$options.filters.nlToBr(item.content)"></div>

                        <div v-if="item.documents && item.documents.length > 0">
                            <div class="text-primary mb-1">{{$t('label_documents')}}</div>
                            <div v-for="doc in item.documents">
                                <a class="text-success" :href="$base_url + '/deal_emails/'">{{doc.display_name}}</a>
                            </div>
                            <!--<a  v-for="doc, ind in item.documents" class="text-success mr-1" :href="$base_url + '/deal_emails/'">{{doc.display_name + ((ind+1) ==item.documents.length? '' :',')}}</a>-->
                        </div>
                        <br>
                        <div v-if="item.emails">
                            <div class="text-primary mb-1">{{$t('label_recipients')}}</div>
                            <div>
                                <feather-icon class="mr-1" icon="MailIcon"></feather-icon>
                                <span class="text-secondary">{{item.emails}}{{(item.send_to_contact == 1)? ((item.emails? ', ' : '') + (item.agreement?item.agreement.email : '')) : ''}}</span>
                            </div>

                        </div>
                        <div v-if="item.isScheduled && item.is_sended == 0">
                            <feather-icon class="mr-1" icon="ClockIcon"></feather-icon>
                            <span class="text-secondary">{{$t('label_email_scheduled_on') + ' ' + formatDate(item.send_at, 'DD.MM.YYYY HH:mm' , 'YYYY-MM-DD HH:mm:ss')}}</span>
                        </div>

                    </b-media-body>
                </b-media>
            </template>
        </infinity-list>

    </div>
</template>

<script>
    import {
        BMedia,
        BMediaAside,
        BMediaBody,
        BAvatar,
        BButton

    } from 'bootstrap-vue'
    import infinityList from '@/views/components/infinityList'
    import {AGREEMENT_DYNAMIC_STORE_NAME, AGREEMENT_PREFIX as PREFIX, transPaymentType} from './../moduleHelper'

    export default {

        components: {
            BMedia,
            BMediaAside,
            BMediaBody,
            BAvatar,
            BButton,
            infinityList
        },

        props: ['moduleItem', 'editedItem', 'relatedDeals'],

        data() {
            return {
                PREFIX,
                AGREEMENT_DYNAMIC_STORE_NAME,
                refreshList: false,
            }
        },
        methods: {

            refreshDataTable() {
                this.refreshList = true;
            },

            resendMail(mail_id){
                this.async('get', '/deal_emails/resend/' +mail_id, {}, function (resp) {
                    // this.$router.push({name: 'mails_' + this.itemData.mail_type});
                }, false);
            }

        },
        created(){
            this.$root.$refs[this.PREFIX + '_CONTENT_TAB'] = this;
        },
        beforeDestroy(){
            delete this.$root.$refs[this.PREFIX + '_CONTENT_TAB'];
        }

    }
</script>
