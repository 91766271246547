<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="$emit('modalShown');initData()"


    >
        <template #modal-title>
            {{$t('label_write_reason')}}
        </template>
        <template #default="{ hide }">


            <validation-observer
                    tag="form"
                    class="p-1"
                    #default="{handleSubmit, reset, invalid}"
                    :ref="PREFIX + '_FORM'"

            >

                <validation-provider
                        class="mt-2"
                        #default="validationProps"
                        :name="$t('label_write_reason')"
                        rules="required"
                        slim
                >
                    <b-form-group
                            :label="$t('label_write_reason')"
                            label-for="meeting_type_id"
                    >
                        <b-form-textarea
                                rows="5"
                                v-model="itemData.status_changing_reason"
                        >

                        </b-form-textarea>

                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        class="mt-2"
                        :name="$t('label_document')"
                        slim
                        #default="validationProps"

                >

                    <file-input

                            v-model="document"
                            class="file--uploader drop-block mb-3"
                            :max-files-count='10'
                            :required="true"
                            :multiple="false"
                            allowed-extensions='*'
                            :max-file-size='15'
                            :url="''"
                    >
                        <template #uploadBody>
                            <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                            <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                            {{$t('label_add_file')}}
                        </template>

                        <template #errorBug="fileErrors">

                            <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                            <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </template>
                    </file-input>


                </validation-provider>

                <b-progress class="progress-form-control" v-if="percentsHttpRequestDone" :value="percentsHttpRequestDone" max="100" show-progress animated></b-progress>

                <div class="d-flex justify-content-end mt-2">
                    <button @click.prevent="$bvModal.hide(PREFIX + '-modal');" type="reset" class=" btn btn-default">{{$t('label_close')}}</button>

                    <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                            class="btn btn-primary">{{(action == 'editing')?$t('label_submit'):$t('label_submit')}}</button>
                </div>

            </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        BFormTextarea,
        BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend, BProgress,
    } from 'bootstrap-vue'
    import  fileInput from '@/views/components/fileUploadInput'
    import {serialize} from 'object-to-formdata';
    import {AGREEMENT_DYNAMIC_STORE_NAME, AGREEMENT_PREFIX, DEAL_PREFIX, PAYMENT_PREFIX, DUE_LAW_PREFIX as PREFIX} from './../moduleHelper'

    export default {
        components: {

            BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend, BProgress,

            ValidationProvider,
            ValidationObserver,
            fileInput
            // vSelect
        },
        props:['editedItem', 'newStatusId'],
        data() {
            return {

                PREFIX: 'confirm-status',

                action: 'adding',

                blankItemData: {
                    deal_status_id: null,
                    status_changing_reason: ''
                    // ocr: 0,
                    // deadline_at: null,
                },

                document:[],
                itemData:{},

                restoreChanges: true
            }
        },

        methods: {
            initData(){
                this.restoreChanges = true;
                let item = Object.assign({},this.blankItemData);
                item.deal_status_id = this.newStatusId;
                if(this.editedItem){
                    this.action = 'editing';
                    item.id = this.editedItem.deal_id? this.editedItem.deal_id : this.editedItem.id;

                    this.itemData = item;
                } else {
                    this.action = 'adding';
                }
            },
            resetForm() {
                this.itemData = Object.assign({}, this.blankItemData);
                this.document = [];
                if(this.restoreChanges) {
                    this.$emit('restore-select-value', {});
                } else {
                    this.$emit('close-select', {});
                }

                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){
                this.restoreChanges = false;
                let formData = new FormData();
                // this.itemData.gross_amount = this.itemData.gross_amount.formatPriceToNumber();
                formData = serialize(this.itemData, {}, formData);

                if (this.document.length > 0) {
                    for(let i = 0; i< this.document.length; i++){
                        formData.append('document[]', this.document[i]);
                    }
                }

                if (this.action == 'adding') {

                } else {

                    this.async('put', '/deal/change_status/'  + this.itemData.id, formData, function (resp) {
                        this.$emit('item-edited', resp.data.item);
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    }, false,  {headers:{'Content-Type': 'multipart/form-data'}, showProgress:true});

                }

            }
        },



    }
</script>