<template>
    <b-row>

        <b-col
                cols="12"
                md="12"
                class=" mb-1 "
        >


            <div class="pb-1 mt-1 table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="2"
                            class="d-flex align-items-center justify-content-start mb-md-0"
                    >
                        <!--<label>{{ $t('label_show') }}</label>-->
                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mr-1 mb-1"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>


                    </b-col>


                    <b-col class="ml-auto text-right d-flex align-items-center justify-content-end"  cols="12" md="10">


                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block search--select mb-1"
                                :placeholder="$t('label_search')+'...'"
                        />
                        <b-button class="mb-1 ml-1" variant="primary" @click="$bvModal.show(PREFIX + '-modal')">
                            {{$t('label_add_new')}}
                        </b-button>
                    </b-col>


                </b-row>

            </div>

            <div class="table-container-wrap">
                <b-table

                        :ref="PREFIX+'_TABLE_pay'"
                        class="position-relative transited-table"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="columnsDefs"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"

                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>


                    <template #cell(yourwritings_actions)="data">
                        <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>


                            <b-dropdown-item disabled>
                                <div class="divider">
                                    <div class="divider-text">{{ $t('label_action') }}</div>
                                </div>

                            </b-dropdown-item>


                            <b-dropdown-item @click="deleteYourWriting(data.item.id)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>


                        </b-dropdown>

                    </template>

                    <template #cell(id_pattern)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(pattern_category)="data">
                        {{ data.item.pattern.category? data.item.pattern.category.name : '--' }}
                    </template>
                    <template #cell(m_pattern_category)="data">
                        {{ data.item.category?data.item.category.name: '--'}}
                    </template>
                    <template #cell(m_pattern_name)="data">
                        {{ data.item.pattern_name }}
                    </template>
                    <template #cell(pattern_name)="data">
                        {{ data.item.pattern.pattern_name }}
                    </template>
                    <template #cell(created_at)="data">
                        {{ formatDate(data.item.created_at, 'DD-MM-YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss') }}
                    </template>
                    <template #cell(m_pattern_description)="data">
                        {{ data.item.pattern_description }}
                    </template>
                    <template #cell(pattern_description)="data">
                        {{ data.item.pattern.pattern_description }}
                    </template>
                    <template #cell(report_file)="data">
                        <a
                                class="mr-1"
                                :href="$base_url + MODULE_PREFIX + '_' +PREFIX + 's/download/' + data.item.id +'?type=pdf'">
                            <feather-icon
                                    size="18"
                                    icon="FileIcon"
                            />
                        </a>

                    </template>
                    <template #cell(patterns_actions)="data">

                        <b-button style="min-width: 100px" variant="outline-primary" size="sm"
                                  @click="generateYourPattern(data.item.id_pattern)">
                            {{ $t('label_generate') }}
                        </b-button>
                    </template>


                </b-table>
            </div>
            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-col>
        <process-envelop-pattern-modal @item-added="refreshDataTable" @item-edited="refreshDataTable"  :module-item="moduleItem" :edited-item="tabItem"></process-envelop-pattern-modal>
    </b-row>
</template>

<script>
    import {

        BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BFormFile, BButton, BAlert,BCard, BTooltip, BFormInput, BFormRadio

    } from 'bootstrap-vue'
    import {trSortable} from '@src/utils/directives'

    import vSelect from 'vue-select'

    import  infinityScroll from '@/views/components/infinityScroll'
    import  processEnvelopPatternModal from '../modals/processEnvelopPatternModal'

    import { ENVELOPE_PATTERN_PREFIX as PREFIX, DEAL_PREFIX as MODULE_PREFIX} from './../moduleHelper'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BButton, BAlert, BCard,  BTooltip,BFormInput, BFormRadio,
            vSelect,
            infinityScroll,
            processEnvelopPatternModal
        },
        props: ['moduleItem'],
        data() {
            return {

                PREFIX,
                MODULE_PREFIX,

                module_id: 0,
                tabItem:false,

                columnsDefs: [
                    // {label: '№', key: 'id_pattern',sortable: true},
                    // {label: 'label_category', key: 'pattern_category', sortable: false},
                    {label: 'label_envelope_template', key: 'template_id', sortable: false},
                    // {label: 'label_description', key: 'pattern_description', sortable: false},
                    {label: 'label_creating_date', key: 'created_at', sortable: false},
                    {label: 'label_download', key: 'report_file', sortable: false},
                    {label: 'label_action', key: 'yourwritings_actions'}
                ],

                isBusy:false,
                tableItems: [],
                tableTotal: 0,
                tableData: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'lp',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                filterData:{
                    category:null
                },
                categories:[],
                invoiceData: {}

            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (newVal, Old) {
                    this.refreshDataTable();
                },
                deep: true
            },
            displayed_table: function(cur_table){

                this.displayTable(cur_table);
            },

        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        methods: {


            refreshDataTable: function () {
               this.isBusy = true;

                this.async('get', '/' + this.MODULE_PREFIX+'_' +this.PREFIX + 's' , {
                    params: {
                        deal_id: this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                this.isBusy = false;
                });
            },


            deleteYourWriting(id) {
                this.confirmDeleting((result) => {
                    this.async('delete', '/'+this.MODULE_PREFIX+'_' + this.PREFIX + 's/' + id, {params: {}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },

            generateYourPattern(id){
                this.async('post', '/'+this.MODULE_PREFIX+'_patterns/generate/' + id , {

                        deal_id: this.moduleItem.id,
                        pattern_id: id

                }, function (resp) {
                    this.displayTable('yourwritings');
                });

            },


        },

        created() {

            let self = this;
            this.module_id = this.$router.currentRoute.params.id;

            this.columnsDefs = this.columnsDefs.filter(function (obj) {

               if (obj.key == 'actions' && !self.$can('edit', 'agreement.tab_' + self.PREFIX + 's')) {
                    return false;
                }

                return true;

            });

            this.refreshDataTable();
        },
    }
</script>