<template>
    <b-row>

        <b-col v-if="!showAll">

            <b-alert  :show="showInfoAlert" variant="info" class="mb-2">
                <template v-if="moduleItem.agreement_type == 'legal_service_order_card'">
                    <p class="d-flex justify-content-between" v-if="pay_data.payInfo['lump_sum_hours']">
                        <span>{{$t('label_monthly_limit_of_hours') + ': ' + pay_data.payInfo['lump_sum_hours']}}</span>
                        <span class="pull-right">{{$t('label_remained')+ ': '  + alertTotal}}</span>
                    </p>
                    <p v-else class="d-flex justify-content-between">
                        <span>{{pay_data.payInfo['name'] + ' ' + pay_data.payInfo['common_pay'] + ' zl'}}</span>
                        <span class="pull-right">{{$t('label_remained') + ': ' + alertTotal + ' ' +$t('label_pln_net')}}</span>
                    </p>
                </template>
                <p v-else class="d-flex justify-content-between" v-for="info,k in pay_data.payInfo">
                    <span>{{$t('label_'+info['alias']) + ' ' + info['common_pay'] + ' zl'}}</span>
                    <span v-if="pay_data.payInfo.length == k + 1" class="pull-right">{{$t('label_remained')+ ': ' +alertTotal +' ' +$t('label_pln_net')}}</span>
                </p>
            </b-alert>
            <b-alert :show="showDanderAlert" variant="danger" class="mb-2 d-flex align-items-center">
                <feather-icon icon="AlertTriangleIcon" color="red" size="16" class="mr-50"></feather-icon>
                {{$t('warning_activity_time_exceeded').sprintf(90)}}
            </b-alert>
        </b-col>


        <b-col
                cols="12"
                md="12"
                class=" mt-1"
        >

            <div class="pb-1 table-top-toolbar">

                <b-row>

                    <b-col
                            cols="12"
                            md="12"
                            class="d-flex align-items-center justify-content-start mb-md-0"
                    >

                        <v-select
                                v-model="tableData.perPage"
                                :searchable="false"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mr-1 mb-1"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>

                        </v-select>

                        <v-select style="min-width: 260px"
                                  :disabled="isCalcTypeDisabled"
                                  v-model="filterData.calc_type"
                                  :searchable="false"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="calcTypes"
                                  :reduce="val => val.alias"
                                  :clearable="true"
                                  class="mr-1 mb-1"
                                  :placeholder="$t('label_calculation_type')"
                        >
                            <template v-slot:option="option">
                                <span class="">{{ $t(option.label) }}</span>
                            </template>
                            <template v-slot:selected-option="option">
                                <span>{{ $t(option.label) }}</span>
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <column-chooser class="mb-1" v-model="selectedColumns" :not-touch="[]"
                   :columns="columnsDefs"></column-chooser>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                        <b-form-radio v-for="item in tableTypes"
                                      class="mr-1 mb-1"
                                      v-model="displayed_table"
                                      button
                                      size="sm"
                                      button-variant="outline-primary"
                                      :value="item.alias"
                        >
                            <span style="white-space: nowrap;">{{$t(item.label)}}</span>
                        </b-form-radio>
                        <b-form-radio v-if="showAll"
                                class="mr-1 mb-1"
                                v-model="displayed_table"
                                button
                                size="sm"
                                button-variant="outline-primary"
                                value="alerts"
                        >
                            <span style="white-space: nowrap;">{{$t('label_alerts')}}</span>
                        </b-form-radio>
                    </b-col>

                </b-row>
                <b-row class="mt-1" v-if="showAll && (displayed_table == 'this_month' || displayed_table == 'all_tasks')">
                    <b-col cols="12">
                        <b-form-checkbox
                                v-model="show_pdf_detailing"
                                :value="true"
                                :unchecked-value="false"
                                @change="displayTable()"
                        >
                            {{$t('label_show_activities')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </div>

            <div class="table-container-wrap">
                <b-table
                        v-if="showPayTable"
                        :ref="PREFIX+'_TABLE_pay'"
                        class="position-relative transited-table styled-footer"
                        :items="tableItems"
                        responsive
                        no-border-collapse
                        :busy="isBusy"
                        :fields="selectedColumns"
                        primary-key="id"
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :foot-clone="showFooter"
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                        :tbody-tr-class="resolveRowClassVariant"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>

                    <template #head(checkboxes)="data">
                        <b-dropdown v-if="checkedIds.length > 0"
                                    class="selected-items-dropdown"
                                    variant="link"
                                    no-caret
                                    :right="$store.state.appConfig.isRTL"
                        >
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
                            </template>

                            <b-dropdown-item
                                    @click="deleteSelectedItems()">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete_selected') }}</span>
                            </b-dropdown-item>

                        </b-dropdown>

                        <span v-else class="text-secondary">{{ $t(data.label).toUpperCase() }}</span>

                    </template>
                    <template #cell(checkboxes)="data">

                        <!--<label>{{ $t('label_recommended') }}</label>-->
                        <b-form-checkbox
                                :value="data.item.id"
                                v-model="checkedIds"
                        >

                        </b-form-checkbox>

                    </template>
                    <template #cell(declarative_time)="data">

                        <!--<label>{{ $t('label_recommended') }}</label>-->
                        <span :class="'badge badge-light-'+ (data.item.declarative_time==1? 'warning' : 'secondary')">{{data.item.declarative_time==1? $t('label_declarative_time') : $t('label_real_time')}}</span>

                    </template>
                    <template #cell(payment_netto)="data">
                        {{data.item.payment_netto + $t('label_pln_net')}}

                    </template>
                    <template #cell(net_report_amount)="data">
                        {{(data.item.net_report_amount?data.item.net_report_amount:0) +' '+ $t('label_pln_net')}}

                    </template>

                    <template #cell(user_name)="data">
                        {{data.item.user ? data.item.user.name : '--'}}
                    </template>

                    <template #cell(report_date)="data">

                        {{formatDate(data.item.report_date, "DD.MM.YYYY HH:mm", 'unix' )}}&nbsp;<feather-icon
                            v-if="data.item.is_cyclic == 1" icon="ClockIcon"></feather-icon>

                    </template>
                    <template #cell(first_pay_at)="data">

                        {{formatDate(data.item.first_pay_at,"DD.MM.YYYY HH:mm", 'unix') + ' - ' +
                        formatDate(data.item.last_pay_at,"DD.MM.YYYY HH:mm", 'unix')}}

                    </template>

                    <template #cell(report_file)="data">

                        <a v-if="$can('view','deal.tab_working_time_report')" target="_blank"
                           :href="$domain + data.item.report_dir + data.item.report_file" style="font-size: 20px;">
                            <feather-icon icon="FileIcon"></feather-icon>
                        </a>

                    </template>

                    <template #cell(agreement_deal_number)="data">

                        <template v-if="data.item.agreement">
                            <router-link v-if="data.item.agreement.is_deal && data.item.agreement.deal && $can('view', 'deal.tab_details')"
                                         :to="{name: 'deal-details', params:{id:data.item.agreement.deal.id}}">
                                {{data.item.agreement.deal_number}}
                            </router-link>
                            <!--<router-link v-else-if="data.item.agreement &&  $can('view', 'agreement.tab_details')"-->
                                         <!--:to="{name: 'agreement-details', params:{id:data.item.agreement.id}}">-->
                                <!--{{data.item.agreement.deal_number}}-->
                            <!--</router-link>-->
                            <template v-else>
                                {{data.item.agreement.deal_number}}
                            </template>
                        </template>
                        <span v-else>--</span>

                    </template>

                    <template #cell(actions)="data">
                        <b-dropdown
                                dropleft
                                boundary="window"
                                variant="link"
                                no-caret
                                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                                :right="$store.state.appConfig.isRTL"
                        >

                            <template #button-content>
                                <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                />
                            </template>


                            <b-dropdown-item disabled>
                                <span class="badge badge-light-primary d-block">{{ $t('label_change_status') }}</span>
                            </b-dropdown-item>

                            <b-dropdown-item v-if="data.item.status == 2" @click="continueTimer(data.item)">
                                <span class="align-middle ml-50">{{ $t('label_start') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="data.item.status == 2" @click="moveToNotCalculated(data.item.id)">
                                <span class="align-middle ml-50">{{ $t('label_somplete_the_action') }}</span>
                            </b-dropdown-item>

                            <b-dropdown-item disabled>
                                <div class="divider">
                                    <div class="divider-text">{{ $t('label_action') }}</div>
                                </div>

                            </b-dropdown-item>
                            <b-dropdown-item
                                    @click="$store.commit('app/setEditedWorkingTime', data.item);$bvModal.show(PREFIX + '-modal')"
                                    v-if="displayed_table == 'declarative_time'">
                                <feather-icon icon="EditIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteItem(data.item.id)">
                                <feather-icon icon="TrashIcon"/>
                                <span class="align-middle ml-50">{{ $t('label_delete') }}</span>
                            </b-dropdown-item>


                        </b-dropdown>

                    </template>

                    <template #foot()="data">
                        <i>{{ '' }}</i>
                    </template>
                    <template #foot(rate)="data">
                        <span class="badge badge-light-danger justify-content-center d-flex align-items-center">{{ $t('label_sum') }}</span>
                    </template>
                    <template #foot(payment_netto)="data">
                        <span class="justify-content-center d-flex align-items-center">{{(summaryData.sum_netto?summaryData.sum_netto : '0')  + ' ' + $t('label_pln_net').toLowerCase()}}</span>
                    </template>
                    <template #foot(worked_time_seconds)="data">
                        <span class="justify-content-center d-flex align-items-center"> {{formatSeconds(summaryData.total_spent_time, 'HH:mm:ss')}}</span>
                    </template>
                </b-table>
            </div>
            <!--<div class="selected-sum"></div>-->
            <!--pagination-->
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="tableTotal"
                                :per-page="tableData.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-col>

    </b-row>
</template>

<script>
    import {

        BTable,
        BDropdownItem,
        BDropdown,
        BPagination,
        BFormCheckbox,
        BFormFile,
        BButton,
        BAlert,
        BCard,
        BTooltip,
        BFormRadio

    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import {
        AGREEMENT_DYNAMIC_STORE_NAME,
        DEAL_PREFIX as MODULE_PREFIX,
        WORKING_TIME_PREFIX as PREFIX,
        payReportTypes,
        calcCommonNetSum,
        workTimeTables,
        calcTypes
    } from './../moduleHelper'

    import columnChooser from '@/views/components/columnChooser'

    export default {
        components: {

            BTable, BDropdownItem, BDropdown, BPagination, BFormCheckbox, BButton, BAlert, BCard, BTooltip, BFormRadio,
            vSelect,
            columnChooser
        },
        props: ['moduleItem', 'showAll', 'extraFilterData'],
        data() {
            return {
                AGREEMENT_DYNAMIC_STORE_NAME,
                PREFIX,
                MODULE_PREFIX,
                calcCommonNetSum,
                tableTypes: [],
                tabItem: false,

                workTimer: {},
                selectedColumns: [],
                columnsDefs: [],

                columnsDefs_declarative_time: [

                    {label: 'label_#', key: 'checkboxes', thClass: 'table-action-wrap'},
                    {label: 'label_deal_number', key: 'agreement_deal_number'},
                    {label: 'label_adding_date', key: 'executed_date', formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix')
                        }
                    },

                    {label: 'label_type_of_activity', key: 'declarative_time'},
                    {label: 'label_name', key: 'name_activity', sortable: false},
                    {label: 'label_user', key: 'user_name'},
                    {label: 'label_hourly_rate', key: 'pay_rate', sortable: false},
                    {label: 'label_time_of_the_activity', key: 'worked_time_seconds', formatter(v) {
                            return formatSeconds(v, 'HH:mm:ss')
                        }, sortable: false
                    },
                    {label: 'label_total_net_amount', key: 'payment_netto', sortable: false},
                    {label: 'label_action', key: 'actions', sortable: false},

                ],
                columnsDefs_this_month: [
                    // {label: '№', key: 'id', thClass: 'hidden', tdClass: 'hidden',},
                    {label: 'label_#', key: 'checkboxes'},
                    {label: 'label_deal_number', key: 'agreement_deal_number'},
                    {label: 'label_report_date', key: 'report_date', formatter(v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'unix')
                        }
                    },

                    {label: 'label_generation_period', key: 'first_pay_at', sortable: false},
                    {label: 'label_net_report_amount', key: 'net_report_amount', sortable: false}, //todo if !$isHourlyPayment
                    {label: 'label_pdf_report', key: 'report_file', sortable: false},
                ],
                columnsDefs_alerts: [
                    // {label: '№', key: 'id'},
                    {label: 'label_deal_number', key: 'deal_number'},
                    {label: 'label_type_of_activity', key: 'total', formatter(v) {
                            return v ? v.formatPrice() : '--'
                        }},
                ],

                displayed_table: 'declarative_time',
                showPayTable: true,

                tableData: {},

                isBusy: false,
                tableItems: [],
                tableTotal: 0,
                tableData_default: {
                    currentPage: 1,
                    perPage: 100,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },
                showFooter: false,
                checkedIds: [],
                summaryData: {},
                pay_data: {
                    payInfo: [
                        //     {name: 'ryzhalt', common_pay: 12}
                    ],
                    paySum: 0,
                    // alreadyPaid: 9
                    show_pay_sum_hours: false,
                    pay_sum_hours: false
                },

                needUpdateColumns: false,
                showDanderAlert: false,
                alertTotal: 0,

                calcTypes,

                filterData: {
                    calc_type: null
                },

                isCalcTypeDisabled: false,
                show_pdf_detailing:false,
            }
        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            filterData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            extraFilterData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function () {
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            displayed_table: function (cur_table) {

                this.displayTable(cur_table);
            },

        },
        computed: {
            dataMeta: (context) => {

                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },
            showInfoAlert: (context) => {
                if (typeof context.pay_data.payInfo == 'array') {
                    return context.pay_data.payInfo.length > 0;
                } else if (typeof context.pay_data.payInfo == 'object') {
                    return Boolean(context.pay_data.payInfo.name || context.pay_data.payInfo.length > 0);
                }
            }

        },
        methods: {


            refreshDataTable: function () {
                this.isBusy = true;
                let url = '';

                let params = {
                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc ? true : null,

                };

                this.isCalcTypeDisabled = true;

                if (this.displayed_table == 'declarative_time' || this.displayed_table == 'real_time' || this.show_pdf_detailing) {
                    url = '/' + this.PREFIX;
                    this.showFooter = true;
                    this.isCalcTypeDisabled = this.displayed_table != 'declarative_time';
                } else if (this.displayed_table == 'this_month' || this.displayed_table == 'all_tasks') {
                    this.showFooter = false;
                    url = '/'  + this.PREFIX + '/api_pdf_' + this.PREFIX;
                } else if (this.displayed_table == 'alerts') {
                    this.showFooter = false;
                    url = '/deal_workingtime/api_getReportAlertData';
                }

                params.extra_search = this.displayed_table;

                if(this.isCalcTypeDisabled){
                    params.calc_type = null;
                    this.filterData.calc_type = null;
                } else {
                    params.calc_type = this.filterData.calc_type;
                }

                if(this.showAll) {
                    params.executed_date_from = this.extraFilterData.executed_date_from;
                    params.executed_date_to = this.extraFilterData.executed_date_to;
                    params.deal_id = this.extraFilterData.deal_id;
                    // params.solicitor_id = this.filterData.solicitor_id;
                    params.created_user = this.extraFilterData.created_user_id;
                    params.declarative_time = this.extraFilterData.declarative_time;
                    params.show_not_assigned_to_deal = this.extraFilterData.show_not_assigned_to_deal;
                } else {
                    params[this.MODULE_PREFIX + '_id'] = this.moduleItem.id ? this.moduleItem.id : this.$router.currentRoute.params.id;

                }

                this.async('get', url, {
                    params: params
                }, function (resp) {

                    if (this.displayed_table == 'declarative_time' || this.displayed_table == 'real_time') {
                        this.summaryData = resp.data.items[0] ? resp.data.items[0].summary : {};

                    }
                    this.tableItems = resp.data.total > 0 ? resp.data.items : [];
                    this.tableTotal = resp.data.total;
                    if (this.needUpdateColumns) {
                        this.needUpdateColumns = false;

                        if (this.displayed_table == 'declarative_time' || this.displayed_table == 'real_time' || this.show_pdf_detailing) {
                            this.columnsDefs = this.columnsDefs_declarative_time;
                        } else if (this.displayed_table == 'this_month' || this.displayed_table == 'all_tasks') {
                            this.columnsDefs = this.columnsDefs_this_month.filter((item) => item.key != 'actions');
                        } else if(this.displayed_table == 'alerts') {
                            this.columnsDefs = this.columnsDefs_alerts;
                        }

                    }

                    this.isBusy = false;
                });
            },
            resolveRowClassVariant(item, type) {
                if (!item || type !== 'row') return
                if (this.checkedIds.includes(item.id)) return 'row-success';
            },
            deleteSelectedItems() {
                this.confirmDeleting((result) => {

                    this.async('post', '/' + this.PREFIX + '/api_delete_working_time_reports', {selected_ids: this.checkedIds}, function (resp) {
                        let working_data = this.workTimer.getTimerData();
                        if (working_data && working_data.client_working_time_id && this.checkedIds.includes(working_data.client_working_time_id)) {
                            this.workTimer.resetTimer();
                        }
                        if (!this.showAll && this.pay_data.paySum != 'undefined') {
                            this.getReportAlertData(this.pay_data.paySum, this.show_pay_sum_hours, this.pay_sum_hours);
                        }
                        this.checkedIds = [];
                        this.refreshDataTable();
                    });

                })

            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.PREFIX + '/api_delete_working_time_report/' + id, {}, function (resp) {

                        let working_data = this.workTimer.getTimerData();
                        if (working_data && working_data.client_working_time_id == id) {
                            this.workTimer.resetTimer();
                        }
                        if (!this.showAll && this.pay_data.paySum != 'undefined') {
                            this.getReportAlertData(this.pay_data.paySum, this.show_pay_sum_hours, this.pay_sum_hours);
                        }
                        this.refreshDataTable();
                    });
                })
            },

            getTableTypeOptions() {

                let tableTypes = workTimeTables;
                if(!this.showAll){
                    if (this.moduleItem.agreement_type === 'provision_legal_services' || this.moduleItem.agreement_type === 'provision_redress_services') {
                        tableTypes = tableTypes.filter((el) => {
                            if (el.alias == 'this_month') {
                                return false;
                            }
                            return true;
                        });
                    }
                }

                this.tableTypes = tableTypes;
            },

            displayTable() {

                // this.displayTable(cur_table);
                this.tableData = Object.assign({}, this.tableData_default); //will run refresh table
                this.needUpdateColumns = true;
            },

            getPageData() {
                this.getTableTypeOptions();
                this.displayTable('declarative_time');
                if(!this.showAll) {
                    this.getDealAlarmData();
                }
            },
            getDealAlarmData() {

                this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + '/totals', {params: {deal_id: this.moduleItem.id ? this.moduleItem.id : this.$router.currentRoute.params.id}}, function (res) {
                    if (res.data.payInfo.name || res.data.payInfo.length) {
                        this.pay_data.paySum = res.data.paySum;
                        this.pay_data.show_pay_sum_hours = false;
                        this.pay_data.pay_sum_hours = false;

                        if (this.moduleItem.agreement_type == 'legal_service_order_card') {
                            this.pay_data.show_pay_sum_hours = !!res.data.payInfo.lump_sum_hours;
                            this.pay_data.pay_sum_hours = (this.pay_data.show_pay_sum_hours && parseFloat(res.data.payInfo.lump_sum_hours)) ? res.data.payInfo.lump_sum_hours : 0;
                            this.getReportAlertData(this.pay_data.paySum, this.pay_data.show_pay_sum_hours, this.pay_data.pay_sum_hours);
                        } else {
                            this.getReportAlertData(this.pay_data.paySum, 0, false);
                        }
                    }

                    this.pay_data = res.data;
                });
            },
            getReportAlertData(pay_sum, show_pay_sum_hours, pay_sum_hours) {
                this.async('get', '/' + this.MODULE_PREFIX + '_' + this.PREFIX + '/api_getReportAlertData', {
                    params: {
                        deal_id: this.moduleItem.id,
                        show_pay_sum_hours: show_pay_sum_hours,
                        pay_sum_hours: pay_sum_hours
                    }
                }, function (res) {
                    if (typeof res.data.already_paid != 'undefined') {
                        this.alertTotal = (parseFloat(pay_sum) - parseFloat(res.data.already_paid)).toFixed(2);
                        if ((parseFloat(pay_sum) - parseFloat(res.data.already_paid)) < 0 || parseFloat(res.data.already_paid) >= parseFloat(pay_sum) / 100 * 90) {
                            this.showDanderAlert = true;
                        } else {
                            this.showDanderAlert = false;
                        }
                    } else if (typeof res.data.spend_hours != 'undefined') {
                        this.alertTotal = (parseFloat(pay_sum_hours) - parseFloat(res.data.spend_hours)).toFixed(2);
                        if ((parseFloat(pay_sum_hours) - parseFloat(res.data.spend_hours)) < 0 || parseFloat(res.data.spend_hours) >= parseFloat(pay_sum_hours) / 100 * 90) {
                            this.showDanderAlert = true;
                        } else {
                            this.showDanderAlert = false;
                        }
                    }
                });
            },
            continueTimer(item) {

                if (!this.workTimer.isTimerReset()) {
                    this.showToast('warning', this.$t('label_complete_the_current_task'));
                } else {
                    this.workTimer.startFreshTimerFromRecord(item.id);
                }
            },
            moveToNotCalculated(id) {
                if(!this.workTimer.isTimerReset()){
                    this.workTimer.stopTimer(true,true,true);
                } else {
                    this.async('put', this.PREFIX + '/api_working_move_to_not_calc/' + id, {status: 1}, function (res) {
                        this.displayed_table = 'declarative_time';
                        this.refreshDataTable();
                    });
                }

            },

        },

        created() {

            this.getPageData();



            let self = this;
            this.module_id = this.$router.currentRoute.params.id;

            this.columnsDefs_declarative_time = this.columnsDefs_declarative_time.filter(function (obj) {
                if ((obj.key == 'actions' || obj.key == 'checkboxes') && !self.$can('edit', self.MODULE_PREFIX + '.tab_working_time_report')) {
                    return false;
                } else if(!self.showAll  && obj.key == 'agreement_deal_number') {
                    return false;
                }
                return true;

            });

            this.columnsDefs_this_month = this.columnsDefs_this_month.filter(function (obj) {
                if ((obj.key == 'actions' || obj.key == 'checkboxes') && !self.$can('edit', self.MODULE_PREFIX + '.tab_working_time_report')) {
                    return false;
                } else if(!self.showAll  && obj.key == 'agreement_deal_number') {
                    return false;
                }
                return true;

            });

        },
        mounted() {
            this.workTimer = this.$root.$refs.worktimer;
            this.$root.$refs[this.PREFIX + '_TAB'] = this;
        },

        beforeDestroy() {
            delete this.$root.$refs[this.PREFIX + '_TAB'];
        },

    }
</script>