<template>
    <!--static-->
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            @hide="resetForm()"
            @shown="initData()"

    >
        <template #modal-title>
            {{action == 'editing'?$t('label_add_procuration'):$t('label_add_procuration')}}
        </template>
        <template #default="{ hide }">


        <validation-observer
                tag="form"
                class="p-1"
                #default="{handleSubmit, reset, invalid}"
                :ref="PREFIX + '_FORM'"

        >

            <validation-provider
                    #default="validationProps"
                    :name="$t('label_solicitor')"
                    rules="required"
                    slim
            >
                <b-form-group
                        :label="$t('label_solicitor')"
                        label-for="last_name"
                >
                    <infinity-scroll

                                     selected-prop="id"
                                     :multiple="false"
                                     url="/solicitor"
                                     :placeholder="$t('label_solicitor')"

                                     :default-selection="chosenSolicitor"
                                     v-model="itemData.solicitor_id"

                    >
                        <template #label="{item}">{{ item.name }}</template>

                    </infinity-scroll>


                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                        {{ validationProps.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
            <label class="mt-1 mb-1">{{$t('label_specify_the_scope_of_validity_of_the_power_of_attorney')}}</label>
            <b-row>
                <b-col cols="12" md="6">
                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_date_from')"
                            rules="required"
                            class="flex-grow-1"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_date_from')"
                                label-for="meeting_type_id"
                        >

                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"

                                    :value="itemData.date_from_at"
                                    @input="itemData.date_from_at = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>

                <b-col cols="12" md="6" v-if="itemData.is_termless == 0">
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_date_till')"
                            rules="required"
                            class="flex-grow-1"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_date_till')"
                                label-for="meeting_type_id"
                        >

                            <custom-date-picker
                                    :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                    :locale="$store.state.appConfig.locale"
                                    :placeholder="$t('label_DD-MM-YYYY')"

                                    :value="itemData.date_to_at"
                                    @input="itemData.date_to_at = $event"
                            >
                                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
                                <div slot="label">
                                    <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                </div>
                            </custom-date-picker>

                            <!--@on-change="(val) => {$emit('update:dateFromFilter', (val && val[0])?$moment(val[0]).format('DD-MM-Y'):null);}"-->

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>



            <validation-provider
                    class="mt-2"
                    #default="validationProps"
                    :name="$t('label_dont_specify_an_end_date')"
                    slim
            >
                <b-form-group
                        label=""
                >

                    <b-form-checkbox
                            id="proc-timeless"
                            value="1"
                            unchecked-value="0"
                            v-model="itemData.is_termless"
                    >
                        <label for="proc-timeless">{{ $t('label_dont_specify_an_end_date') }}</label>
                    </b-form-checkbox>
                </b-form-group>
            </validation-provider>



            <div class="d-flex justify-content-end mt-2">
                <button @click.prevent="$bvModal.hide(PREFIX + '-modal')" type="reset" class=" btn btn-default">{{$t('label_cancel')}}</button>

                <button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)" type="submit"
                        class="btn btn-primary waves-effect waves-light">{{(action == 'editing')?$t('label_save'):$t('label_add')}}</button>
            </div>

        </validation-observer>

        </template>

    </b-modal>
</template>


<script>
    import { ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
         BFormTextarea,
        BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BInputGroupAppend, BInputGroup, BFormCheckbox, BInputGroupPrepend,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import  infinityScroll from '@/views/components/infinityScroll'
    import {AGREEMENT_DYNAMIC_STORE_NAME, DEAL_PREFIX, PROCURATION_PREFIX as PREFIX} from '../../deal/moduleHelper'

    export default {
        components: {

            BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BInputGroup, BInputGroupAppend, BFormCheckbox, BInputGroupPrepend,

            ValidationProvider,
            ValidationObserver,
            vSelect,
            infinityScroll
        },
        props:['moduleItem','editedItem'],
        data() {
            return {
                AGREEMENT_DYNAMIC_STORE_NAME,
                PREFIX,
                MODULE_PREFIX: DEAL_PREFIX,
                action: 'adding',

                blankItemData: {
                    deal_id:null,
                    solicitor_id: null,
                    date_from_at: '',
                    date_to_at: null,
                    is_termless: 0
                },

                itemData: {},

                chosenSolicitor:false,
            }
        },
        watch:{
            itemData: {
               handler: function(newVal){
                   if(this.itemData.is_termless == 1){
                       this.itemData.date_to_at = null
                   }
               },
                deep:true
            }
        },
        methods: {
            initData(){
                let editedItem = this.editedItem;
                if (editedItem) {
                    this.action = 'editing';

                    let item = Object.assign({}, this.blankItemData);
                    item.id = editedItem.id;
                    for (let prop in item) {
                        if (editedItem.hasOwnProperty(prop)) {
                            item[prop] = editedItem[prop];
                        }
                    }
                    item.date_from_at = formatDate(this.editedItem.date_from_at, 'DD/MM/YYYY', 'unix');
                    item.date_to_at = formatDate(this.editedItem.date_to_at, 'DD/MM/YYYY', 'unix');
                    this.chosenSolicitor = editedItem.solicitor;
                    this.itemData = item;
                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);
                    this.itemData['deal_id'] = this.moduleItem.id;
                }



            },
            resetForm() {
                this.itemData = Object.assign({},this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit(){

                if(this.action == 'adding') {
                    this.async('post', this.MODULE_PREFIX + '_'+this.PREFIX+'s', this.itemData, function(resp){
                        this.$emit('item-added',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });

                } else {
                    this.async('put', this.MODULE_PREFIX + '_'+this.PREFIX+'s/' + this.itemData.id, this.itemData, function(resp){
                        this.$emit('item-edited',{});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                    });
                }

            },

        },

    }
</script>