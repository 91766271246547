<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'deals' }">{{$t('label_deals')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>

        <!--actions panel-->
        <b-card no-body>
            <b-card-body class="tab-tools">
                <div class="text-uppercase mr-1 mb-1"><strong>{{moduleItem.deal_number}}</strong>
                </div>

                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_details')" class="mr-1 " :to="{name:MODULE_PREFIX + '_edit', params:{deal_id:moduleItem.id, id:moduleItem.agreement_id}}">
                    <feather-icon
                            icon="EditIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_edit')}}</span>
                </b-button>
                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_tasks')" class="mr-1 " @click="$bvModal.show(TASK_PREFIX + '-modal')">
                    <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_add_task')}}</span>
                </b-button>
                <b-button v-if="$can('edit', MODULE_PREFIX+'.tab_working_time_report')" variant="outline-primary" class=" mr-1" @click="$bvModal.show(WORKING_TIME_PREFIX + '-modal')">
                    <feather-icon
                            icon="ClockIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_add_action')}}</span>
                </b-button>

                <b-button v-if="$can('edit', MODULE_PREFIX+'.tab_emails')" variant="outline-primary" class="mr-1" @click="$bvModal.show(EMAIL_PREFIX + '-modal')">
                    <feather-icon
                            icon="MailIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_send_email')}}</span>
                </b-button>
                <b-button variant="outline-primary" v-if="$can('edit', MODULE_PREFIX+'.tab_sms')" class="" @click="editedSms = false;$bvModal.show(SMS_PREFIX + '-modal')">
                    <feather-icon
                            icon="BookmarkIcon"
                            class="mr-50"
                    />
                    <span>{{$t('label_send_sms')}}</span>
                </b-button>
            </b-card-body>
        </b-card>


        <b-card no-body>

            <b-card-body>
                <b-tabs content-class="mt-1" >
                    <!-- This tabs content will always be mounted -->
                    <b-tab
                            class="pt-2"
                            v-if="$can('view', MODULE_PREFIX+'.tab_details')"
                            @click="curPageLabel = $t('label_informations')"
                            :title="$t('label_informations')"
                            lazy
                    >

                        <tab-details @refetch-module-item="fetchModuleItem($router.currentRoute.params.id)" :edited-item="moduleItem" :is-deal="is_deal"></tab-details>

                    </b-tab>



                    <b-tab
                            v-if="$can('view', MODULE_PREFIX+'.tab_notes')"
                            @click="curPageLabel = $t('label_notes')"
                            :title="$t('label_notes')"
                            lazy
                    >
                        <tab-notes
                                @edit-item="editedNote = $event"
                                :ref="NOTE_PREFIX + '_CONTENT_TAB'"
                                :module-item="moduleItem"
                                :is-deal="is_deal"
                        ></tab-notes>

                    </b-tab>

                    <b-tab :ref="TASK_PREFIX + '_TAB'"
                           :is-deal="is_deal"
                           :module-item="moduleItem"
                           v-if="$can('view', MODULE_PREFIX+'.tab_'+TASK_PREFIX+'s')"
                           @click="curPageLabel = $t('label_tasks')"
                           :title="$t('label_tasks')"
                           lazy
                    >

                        <tab-tasks
                                :module-item="moduleItem"
                                :ref="TASK_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedTask = $event;"
                                :is-deal="is_deal"
                        ></tab-tasks>

                    </b-tab>

                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_documents')"
                            @click="curPageLabel = $t('label_documents')"
                            :title="$t('label_documents')"
                            lazy
                    >

                        <tab-documents :module-item="moduleItem" :is-deal="is_deal"></tab-documents>

                    </b-tab>

                    <b-tab ref="agreement_tab"
                           class=""
                           v-if="$can('view', MODULE_PREFIX+'.tab_payments')"
                           @click="curPageLabel = $t('label_payments');"
                           :title="$t('label_payments')"
                           lazy
                    >

                        <tab-payments  :module-item="moduleItem" :is-deal="is_deal"></tab-payments>

                    </b-tab>
                    <b-tab
                            class="pt-2"
                            v-if="$can('view', MODULE_PREFIX+'.tab_rodo')"
                            @click="curPageLabel = $t('label_rodo')"
                            :title="$t('label_rodo')"
                            lazy
                    >

                        <tab-rodo :module-item="moduleItem" :is-deal="is_deal"></tab-rodo>

                    </b-tab>
                    <b-tab
                            :ref="WORKING_TIME_PREFIX + '_TAB'"
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_working_time_report')"
                            @click="curPageLabel = $t('label_working_time_report')"
                            :title="$t('label_working_time_report')"
                            lazy
                    >

                        <tab-working-time-report :module-item="moduleItem" ></tab-working-time-report>

                    </b-tab>
                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_procurations')"
                            @click="curPageLabel = $t('label_procurations')"
                            :title="$t('label_procurations')"
                            lazy
                    >

                        <tab-procurations :module-item="moduleItem" ></tab-procurations>

                    </b-tab>
                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_patterns')"
                            @click="curPageLabel = $t('label_pattern_generator')"
                            :title="$t('label_pattern_generator')"
                            lazy
                    >

                        <tab-patterns :module-item="moduleItem" ></tab-patterns>

                    </b-tab>
                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_emails')"
                            @click="curPageLabel = $t('label_email')"
                            :title="$t('label_email')"
                            lazy
                    >

                        <tab-emails
                                :module-item="moduleItem"
                                :ref="EMAIL_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedEmail = $event;"
                        ></tab-emails>

                    </b-tab>

                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_sms')"
                            @click="curPageLabel = $t('label_sms')"
                            :title="$t('label_sms')"
                            lazy
                    >
                        <tab-sms
                                module-type="deal"
                                :module-item="moduleItem"
                                :ref="SMS_PREFIX + '_CONTENT_TAB'"
                                @edit-item="editedSms = $event;"
                        ></tab-sms>

                    </b-tab>
                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_envelope_patterns')"
                            @click="curPageLabel = $t('label_envelope_generator')"
                            :title="$t('label_envelope_generator')"
                            lazy
                    >
                        <tab-envelop-patterns
                                :module-item="moduleItem"
                                :ref="ENVELOPE_PATTERN_PREFIX + '_CONTENT_TAB'"
                        ></tab-envelop-patterns>

                    </b-tab>

                    <b-tab
                            class=""
                            v-if="$can('view', MODULE_PREFIX+'.tab_history')"
                            @click="curPageLabel = $t('label_history')"
                            :title="$t('label_history')"
                            lazy
                    >

                        <tab-history :module-item="moduleItem" :is-deal="is_deal"></tab-history>

                    </b-tab>

                </b-tabs>
            </b-card-body>
        </b-card>

        <process-note-modal
                :is-deal="is_deal"
                :module-item="moduleItem"
                :edited-item="editedNote"
                @item-edited="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[NOTE_PREFIX + '_CONTENT_TAB'] ? $refs[NOTE_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-note-modal>
        <process-task-modal
                :is-deal="is_deal"
                :module-item="moduleItem"
                :edited-item="editedTask"
                @item-edited="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[TASK_PREFIX + '_CONTENT_TAB'] ? $refs[TASK_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-task-modal>
        <process-email-send-modal
                :module-item="moduleItem"
                :edited-item="editedEmail"
                @item-edited="$refs[EMAIL_PREFIX + '_CONTENT_TAB'] ? $refs[EMAIL_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[EMAIL_PREFIX + '_CONTENT_TAB'] ? $refs[EMAIL_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-email-send-modal>
        <process-sms-modal
                module-type="deal"
                :module-item="moduleItem"
                :edited-item="editedSms"
                @item-edited="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
                @item-added="$refs[SMS_PREFIX + '_CONTENT_TAB'] ? $refs[SMS_PREFIX + '_CONTENT_TAB'].refreshDataTable() : false"
        ></process-sms-modal>


    </div>
</template>

<script>

    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton,

    } from 'bootstrap-vue'

    import processTaskModal from './../agreement/modals/processTaskModal'
    import processWorkingTimeModal from './modals/processWorkingTimeModal'
    import processEmailSendModal from './modals/processEmailSendModal'
    import processSmsModal from '../agreement/modals/processSmsModal'
    import processNoteModal from './../agreement/modals/processNoteModal'

    import tabDetails from './tabs/tabDetails'
    import tabNotes from './../agreement/tabs/tabNotes'
    import tabTasks from './../agreement/tabs/tabTasks'
    import tabRodo from './../agreement/tabs/tabRodo'
    import tabDocuments from './../agreement/tabs/tabDocuments'
    import tabPayments from './../agreement/tabs/tabPayments'
    import tabWorkingTimeReport from './tabs/tabWorkingTimeReport'
    import tabProcurations from './tabs/tabProcurations'
    import tabHistory from './../agreement/tabs/tabHistory'
    import tabPatterns from './tabs/tabPatterns'
    import tabEmails from './tabs/tabEmails'
    import tabSms from '../agreement/tabs/tabSms'
    import tabEnvelopPatterns from './tabs/tabEnvelopPatterns'

    import {DEAL_PREFIX as MODULE_PREFIX,NOTE_PREFIX, ENVELOPE_PATTERN_PREFIX,
        TASK_PREFIX, RODO_PREFIX, WORKING_TIME_PREFIX, PATTERN_PREFIX, EMAIL_PREFIX, SMS_PREFIX} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton,

            processTaskModal,
            processNoteModal,
            processWorkingTimeModal,
            processSmsModal,

            tabDetails,
            tabTasks,
            tabNotes,
            tabRodo,
            tabDocuments,
            tabPayments,
            tabWorkingTimeReport,
            tabHistory,
            tabProcurations,
            tabPatterns,
            processEmailSendModal,
            tabEmails,
            tabSms,
            tabEnvelopPatterns
        },
        data() {
            return {

                TASK_PREFIX,
                NOTE_PREFIX,
                RODO_PREFIX,
                WORKING_TIME_PREFIX,
                PATTERN_PREFIX,
                EMAIL_PREFIX,
                MODULE_PREFIX,
                SMS_PREFIX,
                ENVELOPE_PATTERN_PREFIX,

                is_deal: true,

                editedTask:false,
                editedNote:false,
                editedEmail:false,
                editedSms:false,

                curPageLabel: 'label_informations',
                moduleItem: {},
            }
        },

        created() {

            this.fetchModuleItem(this.$router.currentRoute.params.id);

        },

        beforeDestroy(){

            this.$store.commit('app/setEditedDeal', false);
        },


        methods: {
            fetchModuleItem(id){
                let self = this;
                this.async('get', '/'+this.MODULE_PREFIX+'/' + id, {}, function (resp) {
                    this.moduleItem = resp.data;
                    this.$store.commit('app/setEditedDeal', this.moduleItem);

                    let alias_route_param =  this.$router.currentRoute.params.alias;
                    if(alias_route_param){
                        this.$nextTick(function(){self.$refs[alias_route_param+'_TAB']?self.$refs[alias_route_param+'_TAB'].activate(): false});
                    }
                });

            },
            resolveActiveTab(){
                return false;
            }

        },


    }
</script>

